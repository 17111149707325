<template>
  <app-center>
    <template v-slot:page>
      <v-col class="text-h1">
        Admin only
      </v-col>
    </template>
  </app-center>
</template>

<script>
import AppCenter from '@/views/common/widget/AppCenter.vue';

export default {
  name: 'Admin',
  components: {
    AppCenter,
  },
};
</script>
